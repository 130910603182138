$(document).ready(function(){

    $(".title-page").animated("fadeInDown", "fadeOutUp");
    $(".animation_2, .animation_1, .animation_3, .nyh-name-anim-text").animated("fadeInUp", "fadeOutDown");
    $(".adv-wrap-text, .anim_news_1").animated("fadeInLeft", "fadeOutLeft");
    $(".anim_news_2").animated("fadeInRight", "fadeOutRight");
    $(".image-container").animated("zoomIn", "zoomOut");
    $(".adv-wrap-img__first a, .adv-wrap-img__second, .adv-wrap-img__third a, .wrap-container__photo").animated("flipInY", "flipOutY");

    $(".popup").magnificPopup({
       type : "image"
    });

    function heightDetect() {
        $(".main_head").css("height", $(window).height());
    }
    heightDetect();
    $(window).resize(function () {
        heightDetect();
    });

    $('#back-to-top').on('click', function(e){
        e.preventDefault();
        $('html,body').animate({
            scrollTop: 0
        }, 700);
    });

    $(".toggle_mnu").click(function() {
        $(".sandwich").toggleClass("active");
    });

    $(".top_mnu li a").click(function () {
        $(".top_mnu").fadeOut(600);
        $(".sandwich").toggleClass("active");
        $(".top_text").removeClass("h_opacify");
    }).append("<span>");

    $(".toggle_mnu").click(function () {
        if($(".top_mnu").is(":visible")) {
            $(".top_text").removeClass("h_opacify");
            $(".top_mnu").fadeOut(600);
            $(".top_mnu li a").removeClass("fadeInUp animated");
        } else {
            $(".top_text").addClass("h_opacify");
            $(".top_mnu").fadeIn(600);
            $(".top_mnu li a").addClass("fadeInUp animated");
        }
    });

    $('.imgOpen, .kontakt-wrap__img-container').magnificPopup({
        type: 'image',
        removalDelay: 300,
        mainClass: 'mfp-fade',
        callbacks:{
            resize:changeImgSize,
            imageLoadComplete:changeImgSize,
            change:changeImgSize
        }
    });
    function changeImgSize(){
        var img=this.content.find('img');
        img.css('max-height','100%');
        img.css('width','auto');
        img.css('max-width','auto');
    }
//------------------------------------cutDescriptionText----------------------------------------------
    var size = 230,
        newsContent= $('.news-content'),
        newsText = newsContent.text();

    if(newsText.length > size){
        newsContent.text(newsText.slice(0, size) + ' ...');
    }
//------------------------------------cutDescriptionTextSlider----------------------------------------------
    var size2 = 145,
        newsContent2= $('.slider-text'),
        newsText2 = newsContent2.text();

    if(newsText2.length > size2){
        newsContent2.text(newsText2.slice(0, size2) + ' ...');
    }
//-----------------------------------------------------------------------------------------------------------
    var swiper = new Swiper('.swiper-container-news', {
        nextButton: '.swiper-button-next-news',
        prevButton: '.swiper-button-prev-news',
        slidesPerView: 3,
        paginationClickable: true,
        loop: true,
        spaceBetween: 30,
        breakpoints: {
            767: {
                slidesPerView: 1,
                spaceBetween: 30
            }
        }
    });


});

$(window).load(function() {
    $(".loaderInner").fadeOut();
    $(".loader").delay(400).fadeOut("slow");
});
